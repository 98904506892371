import * as navigation from 'app-level/navigation';
import { useMemo } from 'react';
import { getPortfolioFiltersConfig } from './portfolioFilters.config';

export function usePortfolioFilters() {
  const assetClass = navigation.usePortfolioAssetClass();
  const portfolioFiltersConfig = useMemo(() => assetClass ? getPortfolioFiltersConfig(assetClass) : null, [assetClass]);
  const [currentFilters, setCurrentFilters] = navigation.usePortfolioFilters();
  const { poolFromUrl, setPool } = navigation.usePoolUrl();

  return {
    filtersConfig: portfolioFiltersConfig,
    currentFilters,
    setCurrentFilters,
    poolFromUrl,
    setPool,
  };
}
