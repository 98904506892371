import { FilterOperator } from '__generated__/globalTypes';
import { BaseFiltersConfig, QueryFilter, RangeFilter, FilterValue, RangeFilterConfigEntry } from './filters.types';

export function filterValuesToSearchQuery<T extends BaseFiltersConfig>(
  filters: FilterValue<T>[],
  filterFieldToAbbr: Record<keyof T, string>
) {
  return filters
    .map(filter => {
      if ('value' in filter) {
        const value = filter.value === null ? '' : 
          Array.isArray(filter.value) ? '^' + filter.value.join('^') : filter.value.toString();
        return `${filterFieldToAbbr[filter.name]}-${value}`;
      }
      return `${filterFieldToAbbr[filter.name]}-${filter.min}-${filter.max}`;
    })
    .join(',');
}

export function searchQueryToFilterValues<T extends BaseFiltersConfig>(
  searchQuery: string,
  abbrToFilterField: Record<string, keyof T>,
): FilterValue<T>[] {
  const filtersStr = searchQuery.split(',');
  return filtersStr.map(filterStr => {
    const [name, ...valueStr] = filterStr.split('-');
    if (valueStr.length === 2) {
      const [min, max] = valueStr;

      return {
        name: abbrToFilterField[name],
        min: Number(min),
        max: Number(max),
      };
    } else if (valueStr.length === 1) {
      const [value] = valueStr;
      return {
        name: abbrToFilterField[name],
        value: !value ? null : parseValue(value),
      };
    } else {
      throw new Error('Invalid search query');
    }
    
  });
}

function parseValue(val: string) {
  if (val === 'true') {
    return true;
  } else if (val === 'false') {
    return false;
  } else if (val.startsWith('^')) {
    const values = val.split('^');
    return val.length === 1 ? [] : values.slice(1);
  } else {
    return val;
  }
}

export function filtersToQueryFilters<T extends BaseFiltersConfig>(
  valueFilters: FilterValue<T>[],
  filtersConfig: T,
): QueryFilter<T>[] {
  return valueFilters.map(filter => {
    const config = filtersConfig[filter.name];
    if (config.type === 'range' && 'min' in filter) {
      return convertRangeFilter(config, filter);
    } 
    else if ('value' in filter && Array.isArray(filter.value)) {
      return filter.value.length > 0 ? {
        field_name: filter.name,
        operator: FilterOperator.IS,
        operandList: filter.value,
      } : [];
    }
    else if('value' in filter) {
      return filter.value !== null ? {
        field_name: filter.name,
        operator: FilterOperator.IS,
        operand: filter.value.toString(),
      } : [];
    } else {
      throw new Error('Invalid filter');
    }
  }).flat();
}

function convertRangeFilter<T extends BaseFiltersConfig>(configEntry: RangeFilterConfigEntry, filter: RangeFilter<T>){
  const prepare = configEntry.prepare ? configEntry.prepare : (value: number) => value;
  return [{
    field_name: filter.name,
    operator: FilterOperator.LESS_THAN_OR_EQUALS,
    operand: prepare(filter.max).toString(),
  }, {
    field_name: filter.name,
    operator: FilterOperator.GREATER_THAN_OR_EQUALS,
    operand: prepare(filter.min).toString(),
  }];
}