import { DropdownPillComboboxSingleValueProps } from 'common-ui';
import { DropdownPillComboboxMultiValueProps } from 'common-ui/Combobox/DropdownPillComboboxMultiValue';
import { isString, isType } from 'functions/typeUtils';
import { FilterOperator } from '__generated__/globalTypes';

export type Option = { value: string, label: string };
export const isOption = isType<Option>({ value: isString, label: isString });

export type RangeFilterConfigEntry = {
  displayName: string,
  type: 'range',
  min: number,
  max: number,
  inputFormat?: (value: string) => number,
  displayFormat?: (value: number) => string,
  prepare?: (value: number) => number,
  percentage?: boolean,
  integer?: boolean,
  decimal?: boolean,
}

export type SingleSelectFilterConfigEntry<Op extends Option> = {
  displayName: string,
  type: 'select-single',
  filterOptions: Op[]
} & Required<Pick<
  DropdownPillComboboxSingleValueProps<Op>, 
  'valueToString' | 'getOptionLabel' | 'getOptionValue'
>>;

export type MultiSelectFilterConfigEntry<Op extends Option> = {
  displayName: string,
  type: 'select-multi',
  filterOptions: Op[]
} & Required<Pick<
  DropdownPillComboboxMultiValueProps<Op>, 
  'valueToString' | 'getOptionLabel' | 'getOptionValue'
>>;

export type BooleanFilterConfigEntry = {
  displayName: string,
  truthyLabel: string,
  falseyLabel: string,
  type: 'boolean'
}

export type BaseFiltersConfig = { [key: string]: 
  RangeFilterConfigEntry | 
  SingleSelectFilterConfigEntry<Option> | 
  BooleanFilterConfigEntry |
  MultiSelectFilterConfigEntry<Option>
};


export type RangeFilter<T extends BaseFiltersConfig> = {
  name: keyof T
  min: number
  max: number
}

export type SingleSelectFilter<T extends BaseFiltersConfig> = {
  name: keyof T
  value: string | null
}

export type MultiSelectFilter<T extends BaseFiltersConfig> = {
  name: keyof T
  value: string[] | null
}

export type BooleanFilter<T extends BaseFiltersConfig> = {
  name: keyof T
  value: boolean
}

export type FilterValue<T extends BaseFiltersConfig> = 
  RangeFilter<T> |  SingleSelectFilter<T> | BooleanFilter<T> | MultiSelectFilter<T>; 

export type QueryFilter<T extends BaseFiltersConfig> = {
  field_name: keyof T;
  operator: FilterOperator;
  operand?: string | null;
  operandList?: string[] | null;
}
