import React from 'react';
import {
  CarveCardContainer,
  CarveDetailsContainer,
  CarveDetailsRow,
  CarveDetailsLabel,
  CarveDetailsValue,
  CarveSubheader,
  CarveName,
} from 'ui-kit';
import { DiligenceCard_DiligenceCard } from './__generated__/DiligenceCard';
import { formatCentsToDollars } from 'features/pages/marketplace/BiddingAndPricing/formatting';
import { IconDeviceIpadDown } from '@tabler/icons-react';
import { ToggleButton } from 'common-ui/bool-inputs/ToggleButton';

type DiligenceData = {
  label: string;
  pre: string | number;
  post: string | number;
  base?: string | number;
};

const CONFIG: Record<string, string> = {
  loan_count: '# LOANS',
  current_balance_cents: 'C BAL',
  original_balance_cents: 'O BAL',
  wa_age_months: 'WALA',
  wa_remaining_loan_terms_months: 'WAM',
  wa_coupon: 'GWAC',
  wa_borrower_credit_score: 'FICO',
  wa_ltv: 'LTV',
  wa_dti: 'DTI',
};

type DiligencePreVsPostProps = {
  originalPoolSummary: DiligenceCard_DiligenceCard['original_pool_summary'];
  acceptedBidSummary: DiligenceCard_DiligenceCard['accepted_bid_summary'];
  postDiligenceSummary: DiligenceCard_DiligenceCard['post_diligence_summary'];
  diligenceReports: DiligenceCard_DiligenceCard['diligence_reports'];
};

const DiligencePreVsPost: React.FC<DiligencePreVsPostProps> = ({
  originalPoolSummary,
  acceptedBidSummary,
  postDiligenceSummary,
  diligenceReports,
}) => {
  const data: DiligenceData[] = [
    {
      label: CONFIG.loan_count,
      base: originalPoolSummary.loan_count.toLocaleString(),
      pre: acceptedBidSummary.loan_count.toLocaleString(),
      post: postDiligenceSummary.loan_count.toLocaleString(),
    },
    {
      label: CONFIG.current_balance_cents,
      base: formatCentsToDollars(Number(originalPoolSummary.current_balance_cents)),
      pre: formatCentsToDollars(Number(acceptedBidSummary.current_balance_cents)),
      post: formatCentsToDollars(Number(postDiligenceSummary.current_balance_cents)),
    },
    {
      label: CONFIG.original_balance_cents,
      base: formatCentsToDollars(Number(originalPoolSummary.original_balance_cents)),
      pre: formatCentsToDollars(Number(acceptedBidSummary.original_balance_cents)),
      post: formatCentsToDollars(Number(postDiligenceSummary.original_balance_cents)),
    },
    {
      label: CONFIG.wa_age_months,
      base: originalPoolSummary.wa_age_months.toLocaleString(),
      pre: acceptedBidSummary.wa_age_months.toLocaleString(),
      post: postDiligenceSummary.wa_age_months.toLocaleString(),
    },
    {
      label: CONFIG.wa_remaining_loan_terms_months,
      base: originalPoolSummary.wa_remaining_loan_terms_months.toLocaleString(),
      pre: acceptedBidSummary.wa_remaining_loan_terms_months.toLocaleString(),
      post: postDiligenceSummary.wa_remaining_loan_terms_months.toLocaleString(),
    },
    {
      label: CONFIG.wa_coupon,
      base: `${((originalPoolSummary.wa_coupon ?? 0) * 100).toFixed(3)}%`,
      pre: `${((acceptedBidSummary.wa_coupon ?? 0) * 100).toFixed(3)}%`,
      post: `${((postDiligenceSummary.wa_coupon ?? 0) * 100).toFixed(3)}%`,
    },
    {
      label: CONFIG.wa_borrower_credit_score,
      base: originalPoolSummary.wa_borrower_credit_score.toLocaleString(),
      pre: acceptedBidSummary.wa_borrower_credit_score.toLocaleString(),
      post: postDiligenceSummary.wa_borrower_credit_score.toLocaleString(),
    },
    {
      label: CONFIG.wa_ltv,
      base: `${((originalPoolSummary.wa_ltv ?? 0) * 100).toFixed(2)}%`,
      pre: `${((acceptedBidSummary.wa_ltv ?? 0) * 100).toFixed(2)}%`,
      post: `${((postDiligenceSummary.wa_ltv ?? 0) * 100).toFixed(2)}%`,
    },
    {
      label: CONFIG.wa_dti,
      base: `${((originalPoolSummary.wa_dti ?? 0) * 100).toFixed(2)}%`,
      pre: `${((acceptedBidSummary.wa_dti ?? 0) * 100).toFixed(2)}%`,
      post: `${((postDiligenceSummary.wa_dti ?? 0) * 100).toFixed(2)}%`,
    },
  ];

  const renderTable = (title: string, stage: keyof DiligenceData, showLabels: boolean) => (
    <CarveCardContainer highlight={false}>
      <CarveName>{title}</CarveName>
      <CarveSubheader>{showLabels ? 'Pre-Carving and Pre-Diligence' : ''}</CarveSubheader>
      <CarveDetailsContainer>
        {data.map((item, index) => (
          <CarveDetailsRow key={index}>
            {showLabels && <CarveDetailsLabel>{item.label}</CarveDetailsLabel>}
            <CarveDetailsValue fullWidth={!showLabels}>{item[stage]}</CarveDetailsValue>
          </CarveDetailsRow>
        ))}
      </CarveDetailsContainer>
    </CarveCardContainer>
  );

  return (
    <div>
      <div className="flex gap-4 mt-4">
        <div className='flex gap-2'>
          <ToggleButton
            initialState={true}
            onChange={() => console.log()}
          />
          <span className='text-accent-default font-bold'>I Agree and Accept</span>
        </div>
        <p className='text-gray-400'>Other party has yet to accept</p>
      </div>
      <div className="flex gap-4 mt-4">
        {renderTable('Entire Offering', 'base', true)}
        {renderTable('Pre-Diligence', 'pre', false)}
        {renderTable('Post-Diligence', 'post', false)}
      </div>
      <div className="flex gap-4 mt-4">
        {diligenceReports?.rejected_loans_csv_url && (
          <a
            href={diligenceReports.rejected_loans_csv_url}
            download
            className="flex items-center underline px-2 text-accent-default"
          >
            <IconDeviceIpadDown size={20} className='text-slate-200'/>
            <span className="pl-1">Export List of Loans Removed (.CSV)</span>
          </a>
        )}
        {diligenceReports?.post_diligence_loans_csv_url && (
          <a
            href={diligenceReports.post_diligence_loans_csv_url}
            download
            className="flex items-center underline px-2 text-accent-default"
          >
            <IconDeviceIpadDown size={20} className='text-slate-200'/>
            <span className="pl-1">Export Comp (.CSV)</span>
          </a>
        )}
      </div>
    </div>
  );
};

export default DiligencePreVsPost;
