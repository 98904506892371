import { Dialog, DialogProps } from 'common-ui/Dialog';
import { useEffect, useState } from 'react';
import { BaseButton } from 'common-ui';
import { CloseButton, CompactDialogContainer, CompactDialogHeader } from './commonStyles';
import { gql, useMutation, useQuery } from '@apollo/client';
import {
  GetDiligenceNotes,
  GetDiligenceNotesVariables,
} from 'query/__generated__/GetDiligenceNotes';
import { GET_DILIGENCE_NOTES } from 'query/diligence';
import {
  CreateDiligenceNote,
  CreateDiligenceNoteVariables,
} from 'mutation/__generated__/CreateDiligenceNote';
import { CREATE_DILIGENCE_NOTE } from 'mutation/diligenceMutations';
import { NoteStatus } from '__generated__/globalTypes';
import { GetDealDiligence_deal_diligence } from 'query/__generated__/GetDealDiligence';

interface DiligenceNotesDialogProps {
  documentName: string;
  diligenceId: string;
  dialog: DialogProps;
}

const DiligenceNotesDialog: React.FC<DiligenceNotesDialogProps> = ({
  documentName,
  diligenceId,
  dialog,
}) => {
  const [noteText, setNoteText] = useState<string>('');

  const { data, loading, refetch } = useQuery<GetDiligenceNotes, GetDiligenceNotesVariables>(
    GET_DILIGENCE_NOTES,
    {
      variables: { diligence_id: diligenceId },
      skip: !dialog.isOpen || !diligenceId,
      pollInterval: 10000,
    }
  );

  const [createDiligenceNote] = useMutation<CreateDiligenceNote, CreateDiligenceNoteVariables>(
    CREATE_DILIGENCE_NOTE
  );

  const handleAddNote = (text: string) => {
    createDiligenceNote({
      variables: {
        input: {
          diligence_id: diligenceId,
          note: text,
        },
      },
      update: (cache) => {
        const id = `Diligence:${diligenceId}`;
        const diligence = cache.readFragment<GetDealDiligence_deal_diligence>({
          id,
          fragment: gql`
            fragment Diligence on Diligence {
              id
              note_status
            }
          `,
        });

        if (!diligence) {
          return;
        }

        cache.writeFragment({
          id,
          fragment: gql`
            fragment Diligence on Diligence {
              id
              note_status
            }
          `,
          data: { ...diligence, note_status: NoteStatus.READ },
        });
      },
    }).then(() => {
      refetch();
      dialog.setIsOpen(false);
    });
  };

  useEffect(() => {
    if (dialog.isOpen) {
      setNoteText('');
    }
  }, [dialog.isOpen]);

  return (
    <Dialog dialog={dialog} contentComponent={CompactDialogContainer}>
      <CompactDialogHeader>
        Add a Note for "{documentName}"
        <CloseButton onClick={() => dialog.setIsOpen(false)} />
      </CompactDialogHeader>
      <div className="max-h-[444px] flex flex-col overflow-y-auto gap-5">
        {loading && <div>Loading...</div>}
        {data &&
          data.diligenceNotes?.entities?.map((note) => (
            <div key={note?.id}>
              <div className="text-gray-500">
                <span>
                  ({note?.author.given_name}{' '}
                  {note?.author.family_name?.substring(0, 1)})
                </span>
              </div>
              <div className="font-pt-sans-narrow text-xxs text-slate-200 mb-1">
                {note?.created_time
                  ? new Date(parseInt(note.created_time) * 1000).toDateString()
                  : ''}
              </div>
              <div className="text-sm">{note?.note}</div>
            </div>
          ))}
      </div>
      <textarea
        className="text-sm border border-pink-400 rounded-md my-4 p-2 w-full h-28 text-foreground-default bg-background-surface focus:outline-none focus:border-pink-400"
        aria-label="Add Note"
        value={noteText}
        onChange={(e) => setNoteText(e.target.value)}
      />
      <div className="flex justify-between gap-2">
        <BaseButton
          type="secondary"
          label="cancel"
          size="medium"
          onClick={() => dialog.setIsOpen(false)}
        >
          Cancel
        </BaseButton>
        <BaseButton
          label="add-note"
          size="medium"
          disabled={noteText.length === 0}
          onClick={() => {
            handleAddNote(noteText);
          }}
        >
          Add Note
        </BaseButton>
      </div>
    </Dialog>
  );
};

export default DiligenceNotesDialog;

