import { DetailRow } from './BidSummary.styles';
import { useBiddingPricingContext } from './BiddingPricingContext';
import { formatCentsToDollars } from './formatting';
import { ModalNotStyled } from 'common-ui/modal/ModalNotStyled';
import { SvgIcon } from 'common-ui';
import { INFO_BID_SUMMARY_LIST } from 'configs/messages';
import { GetCarves_deal_carves_carve_summary } from 'query/__generated__/GetCarves';
import { useMemo } from 'react';
import { basisPointsToPercent } from 'features/deals/DealStages/EventActionCards/utils';

interface BidSummaryProps {
  carve: GetCarves_deal_carves_carve_summary;
  bidSummary: BidSummaryInfo;
}

function getAverageDuration(summary: BidSummaryInfo): number | undefined {
  if (summary.yieldDuration == null) {
    return summary.spreadDuration;
  } else if (summary.spreadDuration == null) {
    return summary.yieldDuration;
  }
  return (summary.yieldDuration + summary.spreadDuration) / 2;
}

export interface BidSummaryInfo {
  netYield?: number;
  yieldDuration?: number;
  spread?: number;
  spreadDuration?: number;
  purchaseDollarPrice: number;
}

const BidSummary = ({ carve, bidSummary }: BidSummaryProps) => {
  const { bidDetails } = useBiddingPricingContext();

  const participationPercent = bidDetails?.participationPercent.value
    ? Number(bidDetails.participationPercent.value)
    : null;

  const bidBasisPoints = bidDetails?.carve.stipulations?.bid_basis_points; 
  const servicing = Number(bidDetails?.stipulations?.servicingRate.value);
  const gwac = carve.netCoupon * 100;

  const netWeightedAverageCoupon = useMemo(() => {
    if (servicing > 0) {
      return gwac - servicing;
    }
    return gwac;
  }, [gwac, servicing]);

  const calculatedPurchaseDollarPrice = useMemo(() => {
    if (bidBasisPoints != null && participationPercent != null) {
      const pricePercent = parseFloat(basisPointsToPercent(bidBasisPoints));
      const priceInDollars = (pricePercent / 100) * (carve.unpaidBalance) * (participationPercent / 100);
      return formatCentsToDollars(priceInDollars);
    }
    return '-';
  }, [bidBasisPoints, carve.unpaidBalance, participationPercent]);

  const calculatedNetYieldAtPrice = useMemo(() => {
    if (netWeightedAverageCoupon != null && bidBasisPoints != null) {
      const pricePercent = parseFloat(basisPointsToPercent(bidBasisPoints));
      if (pricePercent !== 0) {
        return (100 * netWeightedAverageCoupon / pricePercent).toFixed(3) + '%';
      }
    }
    return '-';
  }, [netWeightedAverageCoupon, bidBasisPoints]);

  return (
    <>
      <div>
        <ModalNotStyled
          trigger={
            <div className='flex justify-end p-1'>
              <SvgIcon name="info" />
            </div>
          }
        >
          {() => (
            <div className='p-4 bg-grey-950 border border-accent-muted rounded-md min-w-[430px] max-w-[650px] w-full text-foreground-default'>
              <div className='p-2 text-lg'>Bid Summary Details</div>
              <ul className='list-none p-0 m-0'>
                {INFO_BID_SUMMARY_LIST.map((item, index) => (
                  <li key={index} className="border-t border-grey-700 py-1 px-2">
                    <div className='text-sm text-grey-100 pr-24 font-bold'>{item.key}</div>
                    <div className='text-sm text-grey-400 font-bold'>{item.infoText}</div>
                    <div className='text-sm text-grey-400'>{item.explanation}</div>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </ModalNotStyled>
      </div>
      <DetailRow>
        <div>UPB</div>
        <div>{formatCentsToDollars(carve.unpaidBalance)}</div>
      </DetailRow>
      <DetailRow>
        <div>GWAC</div>
        <div>{gwac.toFixed(3)}%</div>
      </DetailRow>
      <DetailRow>
        <div>NWAC</div>
        <div>{netWeightedAverageCoupon.toFixed(3)}%</div>
      </DetailRow>
      <DetailRow>
        <div>Price</div>
        <div>{bidBasisPoints ? basisPointsToPercent(bidBasisPoints) : '-'}%</div>
      </DetailRow>
      <DetailRow>
        <div>Net Yield @ Price</div>
        <div>{calculatedNetYieldAtPrice}</div>
      </DetailRow>
      {/* All below is assumption-based and is for the bidder*/}
      <DetailRow>
        <div>Yield to Maturity</div>
        <div>{bidSummary.netYield?.toFixed(3) ?? '-' }%</div>
      </DetailRow>
      <DetailRow>
        <div>Spread to Curve</div>
        <div>{bidSummary.spread?.toFixed(0) || '- '} bps</div>
      </DetailRow>
      <DetailRow>
        <div>Duration</div>
        <div>{getAverageDuration(bidSummary)?.toFixed(3) || '-'}</div>
      </DetailRow>
      <DetailRow>
        <div>{bidDetails?.defaultAssumptionType ?? 'CDR'}</div>
        <div>{bidDetails?.cdr ?? '-' }%</div>
      </DetailRow>
      <DetailRow>
        <div>{bidDetails?.prepaymentAssumptionType ?? 'CPR'}</div>
        <div>{bidDetails?.cpr}%</div>
      </DetailRow>
      <DetailRow>
        <div>Purchase Dollar Price</div>
        <div>{calculatedPurchaseDollarPrice}</div>
      </DetailRow>
    </>
  );
};

export default BidSummary;
